import React from "react";

export class DGDGTableFilterComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filterText: ""
        };

        this.onTextChange = this.onTextChange.bind(this);
        this.onFilterKeyDown = this.onFilterKeyDown.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onDocumentKeyDown = this.onDocumentKeyDown.bind(this);
    }

    componentDidMount() {
        document.addEventListener("keydown", this.onDocumentKeyDown);
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.filterColumn !== prevProps.filterColumn) {
                this.setState({ filterText: this.props.filterText });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onDocumentKeyDown);
    }

    onTextChange(event) {
        this.setState({ filterText: event.target.value });
    }

    onFilterKeyDown(event) {
        switch (event.keyCode) {
            case 13:
                this.props.onFilterClick(event, this.state.filterText);
                break;
            default:
                break;
        }
    }

    onDocumentKeyDown(event) {
        switch (event.keyCode) {
            case 27:
                this.props.onHideFilterClick();
                break;
            default:
                break;
        }
    }

    onClick(event) {
        if (event.stopPropagation) {
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
        }
        else if (window.event) {
            window.event.cancelBubble = true;
        }
    }

    onCancelClick(event) {
        this.setState({ filterText: "" });
        this.props.onHideFilterClick();
    }

    render() {
        return this.props.showFilter
            ? <div style={{ position: "absolute", top: this.props.top, left: this.props.left, width: "200px", zIndex: "100" }} onClick={(event) => this.onClick(event)}>
                <div className="input-group input-group-sm">
                    <input type="text" className="form-control" placeholder="Search for..." value={this.state.filterText} onChange={(event) => this.onTextChange(event)} onKeyDown={(event) => this.onFilterKeyDown(event)} autoFocus />
                    <span className="input-group-append">
                        <button className="btn btn-secondary dgdg-table-filter" type="button" onClick={(event) => this.props.onFilterClick(event, this.state.filterText)}>
                            <span className="far fa-filter" />
                            <div className="dgdg-table-filter dgdg-table-filter-text">
                                Include
                            </div>
                        </button>
                    </span>
                    <span className="input-group-append">
                        <button className="btn btn-secondary dgdg-table-filter" type="button" onClick={(event) => this.props.onFilterClick(event, this.state.filterText, "exclude")}>
                            <span className="fas fa-ban" />
                            <div className="dgdg-table-filter dgdg-table-filter-text">
                                Exclude
                            </div>
                        </button>
                    </span>
                    <span className="input-group-append">
                        <button className="btn btn-secondary" type="button" onClick={(event) => this.onCancelClick(event)}>
                            <span className="fas fa-times" />
                        </button>
                    </span>
                </div>
            </div>
            : null;
    }
}
