import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGFormCalendarRangeComponent } from "../common/dgdgcalendar/DGDGFormCalendarRangeComponent";
import { DGDGFormMultiSelectComponent } from "../common/dgdgmultiselect/DGDGFormMultiSelectComponent";
import { DGDGFormCheckboxComponent } from "../common/dgdgcheckbox/DGDGFormCheckboxComponent";
import { DGDGQueryComponent } from "../common/dgdgquery/DGDGQueryComponent";

export class FIStoreReportStoreQueryComponent extends React.Component {
    static StoreNumberCookie = "FIStoreReportStoreNumberCookie";
    static VehicleTypeCookie = "FIStoreReportVehicleTypeCookie";
    static DealTypeCookie = "FIStoreReportDealTypeCookie";
    static DateRangeCookie = "FIStoreReportDateRangeCookie";
    static StartDateCookie = "FIStoreReportStartDateCookie";
    static EndDateCookie = "FIStoreReportEndDateCookie";
    static ShowCountsCookie = "FIStoreReportShowCountsCookie";

    vehicleTypes = [
        { key: "All", value: "All" },
        { key: "New", value: "New" },
        { key: "Used", value: "Used" },
        { key: "Electric", value: "Electric" },
        { key: "NonElectric", value: "Non-electric" },
        { key: "UsedLeaseBuyOut", value: "Used Lease Buy Out" }
    ];

    dealTypes = [
        { key: "WithoutHouseDeals", value: "Without House Deals" },
        { key: "WithAllHouseDeals", value: "With All House Deals" },
        { key: "WithoutLeases", value: "Without Leases" },
        { key: "WithHouseDealsNoStorePurchases", value: "With House Deal, No Store Purchases" },
        { key: "LeaseWithoutHouseDeals", value: "Lease, Without House Deals" },
        { key: "FinanceWithoutHouseDeals", value: "Finance, Without House Deals" },
        { key: "CashWithoutHouseDeals", value: "Cash, Without House Deals" }
    ];

    dateRange = [
        { key: "MTD", value: "MTD", startDate: moment().startOf('month'), endDate: moment() },
        { key: "Last Month", value: "Last Month", startDate: moment().subtract(1, "months").startOf('month'), endDate: moment().subtract(1, "months").endOf('month') },
        { key: "2 Months Back", value: "2 Months Back", startDate: moment().subtract(2, "months").startOf('month'), endDate: moment().subtract(2, "months").endOf('month') },
        { key: "YTD", value: "YTD", startDate: moment().date(1).month(0).year(moment().year()), endDate: moment() },
        { key: "Last Year", value: "Last Year", startDate: moment().subtract(1, "years").startOf('year'), endDate: moment().subtract(1, "years").endOf('year') },
        { key: "2 Years Back", value: "2 Years Back", startDate: moment().subtract(2, "years").startOf('year'), endDate: moment().subtract(2, "years").endOf('year') },
        { key: "Custom", value: "Custom", startDate: null, endDate: null }
    ];

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog("FIStoreReportStoreQueryComponent - getDerivedStateFromProps");
            newState.showSpinner = props.isLoadingData;
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);

        let startDate = moment().startOf('month');
        let endDate = moment()

        this.state = {
            startDate: startDate,
            endDate: endDate,
            selectedDateRange: null,
            showSpinner: false,
            selectedStores: null,
            selectedVehicleType: null,
            selectedDealType: null,
            showCount: false,
            showQuery: true
        };

        this.onStoresChange = this.onStoresChange.bind(this);
        this.onVehicleTypeClick = this.onVehicleTypeClick.bind(this);
        this.onDealTypeClick = this.onDealTypeClick.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onShowCountChange = this.onShowCountChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.stores && this.state.selectedStores === null && this.dateRange && this.state.selectedDateRange === null) {
                let selectedStores = this.props.stores;
                let cookieStoreNumbers = CommonService.getCookie(FIStoreReportStoreQueryComponent.StoreNumberCookie);
                if (cookieStoreNumbers.length > 0 && cookieStoreNumbers !== "") {
                    cookieStoreNumbers = cookieStoreNumbers.split(',').map(Number);
                    let filterStores = this.props.stores.filter(store => cookieStoreNumbers.some(item => item === store.store_number));
                    if (filterStores.length > 0) {
                        selectedStores = filterStores;
                    }
                }

                let selectedDateRange = this.dateRange[0];
                let cookieDateRange = CommonService.getCookie(FIStoreReportStoreQueryComponent.DateRangeCookie);
                if (cookieDateRange) {
                    let filterDateRange = this.dateRange.filter(dateRange => String(dateRange.key) === String(cookieDateRange));
                    selectedDateRange = filterDateRange.length > 0 ? filterDateRange[0] : null;
                }

                let selectedStartDate = this.state.startDate;
                let cookieStartDate = CommonService.getCookie(FIStoreReportStoreQueryComponent.StartDateCookie);
                if (cookieStartDate) {
                    selectedStartDate = moment(cookieStartDate);
                }

                let selectedEndDate = this.state.endDate;
                let cookieEndDate = CommonService.getCookie(FIStoreReportStoreQueryComponent.EndDateCookie);
                if (cookieEndDate) {
                    selectedEndDate = moment(cookieEndDate);
                }

                let selectedVehicleType = null;
                let cookieVehicleType = CommonService.getCookie(FIStoreReportStoreQueryComponent.VehicleTypeCookie);
                if (cookieVehicleType) {
                    let filterVehicleType = this.vehicleTypes.filter(vehicleType => String(vehicleType.key) === String(cookieVehicleType));
                    selectedVehicleType = filterVehicleType.length > 0 ? filterVehicleType[0] : null;
                }

                let selectedDealType = null;
                let cookieDealType = CommonService.getCookie(FIStoreReportStoreQueryComponent.DealTypeCookie);
                if (cookieDealType) {
                    let filterDealType = this.dealTypes.filter(dealType => String(dealType.key) === String(cookieDealType));
                    selectedDealType = filterDealType.length > 0 ? filterDealType[0] : null;
                }

                let showCount = CommonService.getCookie(FIStoreReportStoreQueryComponent.ShowCountsCookie) === "true";

                this.setState({
                    selectedStores: selectedStores,
                    selectedDateRange: selectedDateRange,
                    startDate: selectedStartDate,
                    endDate: selectedEndDate,
                    selectedVehicleType: selectedVehicleType,
                    selectedDealType: selectedDealType,
                    showCount: showCount
                }, this.onGetReportClick);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStoresChange(id, changedStores) {
        try {
            let stores = this.props.stores;
            let selectedStores = [];
            stores.forEach((store, index) => {
                if (store.checked !== false)
                    selectedStores.push(store);
            });
            this.setState({ selectedStores: selectedStores });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onVehicleTypeClick(event, vehicleType) {
        try {
            this.setState({ selectedVehicleType: vehicleType });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDealTypeClick(event, dealType) {
        try {
            this.setState({ selectedDealType: dealType });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDateChange(selectedDateRange, startDate, endDate) {
        try {
            this.setState({
                selectedDateRange: selectedDateRange,
                startDate: startDate,
                endDate: endDate
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onGetReportClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(event) {
        let selectedStores = (Array.isArray(this.state.selectedStores) && this.state.selectedStores.length > 0) ? this.state.selectedStores.map(store => Number(store.store_number)).join() : "";
        CommonService.setCookie(FIStoreReportStoreQueryComponent.StoreNumberCookie, selectedStores, 30);
        CommonService.setCookie(FIStoreReportStoreQueryComponent.VehicleTypeCookie, this.state.selectedVehicleType ? this.state.selectedVehicleType.key : "", 30);
        CommonService.setCookie(FIStoreReportStoreQueryComponent.DealTypeCookie, this.state.selectedDealType ? this.state.selectedDealType.key : "", 30);
        CommonService.setCookie(FIStoreReportStoreQueryComponent.DateRangeCookie, this.state.selectedDateRange ? this.state.selectedDateRange.key : "", 30);
        CommonService.setCookie(FIStoreReportStoreQueryComponent.StartDateCookie, moment(this.state.startDate).toDate(), 30);
        CommonService.setCookie(FIStoreReportStoreQueryComponent.EndDateCookie, moment(this.state.endDate).toDate(), 30);
        CommonService.setCookie(FIStoreReportStoreQueryComponent.ShowCountsCookie, this.state.showCount, 30);

        this.props.onGetReportClick(this.state.selectedStores, this.state.selectedVehicleType, this.state.selectedDealType, this.state.startDate, this.state.endDate);
        this.props.onShowCountChange(this.state.showCount);
    }

    onShowCountChange(id, isChecked) {
        try {
            this.setState({ [id]: isChecked });
            CommonService.setCookie(FIStoreReportStoreQueryComponent.ShowCountsCookie, isChecked, 30);
            this.props.onShowCountChange(isChecked);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        let minDate = moment().subtract(2, "years").startOf("year").format("MM/DD/YYYY"),
            maxDate = moment().format("MM/DD/YYYY");

        return <DGDGQueryComponent cssName="dgdg-card-gutter">
            <DGDGFormCalendarRangeComponent applicationInsights={this.props.applicationInsights} row2CssName="dgdg-fi-store-report-date"
                label="Date Range" dateRange={this.dateRange} selectedDateRange={this.state.selectedDateRange} onDateChange={this.onDateChange}
                startId="startDate" startValue={this.state.startDate} startMin={minDate} startMax={maxDate}
                endId="endDate" endValue={this.state.endDate} endMin={minDate} endMax={maxDate} />
            <DGDGFormMultiSelectComponent applicationInsights={this.props.applicationInsights} id="Stores" label="Stores #" data={this.props.stores} itemKey="display_name" value={(Array.isArray(this.state.selectedStores) && this.state.selectedStores.length > 0) ? this.state.selectedStores : null} nonSelectedText="Select Stores" allSelectedText="All Stores" multipleSelectedText="Multiple Stores" onChange={this.onStoresChange} onKeyDown={this.onKeyDown} dropDownCss="dgdg-fi-store-dropdown" />
            <DGDGFormDropdownComponent label="Vehicle Type" itemKey="value" data={this.vehicleTypes} value={this.state.selectedVehicleType ? this.state.selectedVehicleType.value : "Select Vehicle Type"} onItemClick={this.onVehicleTypeClick} onKeyDown={this.onKeyDown} dropDownCss="dgdg-fi-store-vehicle-type" />
            <DGDGFormDropdownComponent label="Deal Type" itemKey="value" data={this.dealTypes} value={this.state.selectedDealType ? this.state.selectedDealType.value : "Select Deal Type"} onItemClick={this.onDealTypeClick} onKeyDown={this.onKeyDown} dropDownCss="dgdg-fi-store-dropdown" />
            {
                this.props.canShowCount
                    ? <DGDGFormCheckboxComponent id="showCount" label="Show Counts" value={this.state.showCount} onChange={this.onShowCountChange} />
                    : null
            }
            <button disabled={this.state.showSpinner || this.state.selectedStores === null || !(this.state.startDate._isValid && this.state.endDate._isValid)}
                className="btn btn-primary" onClick={(event) => this.onGetReportClick(event)}>
                Get Report <span className="fas fa-redo-alt dgdg-icon" />
            </button>
            &nbsp;
            <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
        </DGDGQueryComponent>;
    }
}
