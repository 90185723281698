import React from "react";
import { Link } from "react-router-dom";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DealIssuesSummaryQueryComponent } from "./DealIssuesSummaryQueryComponent";

export class DealIssuesSummaryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            paintTable: false,
            tableTitle: "Deal Issues Summary",
            tableData: []
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onDealCountColumnCallback = this.onDealCountColumnCallback.bind(this);
        this.onIncentiveCountColumnCallback = this.onIncentiveCountColumnCallback.bind(this);
        this.onDMVServiceCountColumnCallback = this.onDMVServiceCountColumnCallback.bind(this);
        this.onDMVFinanceCountColumnCallback = this.onDMVFinanceCountColumnCallback.bind(this);
        this.onTitleCountColumnCallback = this.onTitleCountColumnCallback.bind(this);
        this.onOtherCountColumnCallback = this.onOtherCountColumnCallback.bind(this);
        this.onTotalColumnCallback = this.onTotalColumnCallback.bind(this);
        this.onDealFooterCallback = this.onDealFooterCallback.bind(this);
        this.onIncentiveFooterCallback = this.onIncentiveFooterCallback.bind(this);
        this.onDMVServiceFooterCallback = this.onDMVServiceFooterCallback.bind(this);
        this.onDMVFinanceFooterCallback = this.onDMVFinanceFooterCallback.bind(this);
        this.onAfterMarketFooterCallback = this.onAfterMarketFooterCallback.bind(this);
        this.onTitleFooterCallback = this.onTitleFooterCallback.bind(this);
        this.onOtherFooterCallback = this.onOtherFooterCallback.bind(this);
        this.onTotalFooterCallback = this.onTotalFooterCallback.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "Deal Issues"

    componentDidMount() {
        try {
            console.devLog("DealIssuesSummaryComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/DealIssues/Summary", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("DealIssuesSummaryComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick() {
        try {
            this.setState({ isLoadingData: true });
            this.props.applicationInsights.trackTrace({ message: "/DealIssuesSummaryComponent/onGetReportClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/DealIssues/GetSummaryReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                }
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableData: CommonService.addOrdinalColumn(parsedResponse.table),
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        tableTitle: "",
                        isLoadingData: false,
                        tableData: [],
                        paintTable: true,
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackTrace({ message: "/DealIssues/onGetReportClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("DealIssuesRouteComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDealCountColumnCallback(rowData) {
        return <Link to={"/DealIssues/Detail/" + rowData.store_web_name.split(" ")[0] + "/Deal/Open"}> {rowData.deal}</Link>;
    }

    onIncentiveCountColumnCallback(rowData) {
        return <Link to={"/DealIssues/Detail/" + rowData.store_web_name.split(" ")[0] + "/Incentive/Open"}> {rowData.incentive}</Link>;
    }

    onDMVServiceCountColumnCallback(rowData) {
        return <Link to={"/DealIssues/Detail/" + rowData.store_web_name.split(" ")[0] + "/DMV Service/Open"}> {rowData["dmv Service"]}</Link>;
    }

    onDMVFinanceCountColumnCallback(rowData) {
        return <Link to={"/DealIssues/Detail/" + rowData.store_web_name.split(" ")[0] + "/DMV Finance/Open"}> {rowData["dmv Finance"]}</Link>;
    }

    onAfterMarketCountColumnCallback(rowData) {
        return <Link to={"/DealIssues/Detail/" + rowData.store_web_name.split(" ")[0] + "/Aftermarket/Open"}> {rowData.aftermarket}</Link>;
    }

    onTitleCountColumnCallback(rowData) {
        return <Link to={"/DealIssues/Detail/" + rowData.store_web_name.split(" ")[0] + "/Title/Open"}> {rowData.title}</Link>;
    }

    onOtherCountColumnCallback(rowData) {
        return <Link to={"/DealIssues/Detail/" + rowData.store_web_name.split(" ")[0] + "/Ohter/Open"}> {rowData.other}</Link>;
    }

    onTotalColumnCallback(rowData) {
        return <Link to={"/DealIssues/Detail/" + rowData.store_web_name.split(" ")[0] + "/All/Open"}> {rowData.total}</Link>;
    }

    onDealFooterCallback() {
        let totalCount = 0;
        this.state.tableData.forEach((rowData, index) => { totalCount += rowData.deal; });
        return <Link to={"/DealIssues/Detail/All/Deal/Open"}> {totalCount}</Link>;
    }

    onIncentiveFooterCallback() {
        let totalCount = 0;
        this.state.tableData.forEach((rowData, index) => { totalCount += rowData.incentive; });
        return <Link to={"/DealIssues/Detail/All/Incentive/Open"}> {totalCount}</Link>;
    }

    onDMVServiceFooterCallback(rowData) {
        let totalCount = 0;
        this.state.tableData.forEach((rowData, index) => { totalCount += rowData["dmv Service"] });
        return <Link to={"/DealIssues/Detail/All/DMV Service/Open"}> {totalCount}</Link>;
    }

    onDMVFinanceFooterCallback(rowData) {
        let totalCount = 0;
        this.state.tableData.forEach((rowData, index) => { totalCount += rowData["dmv Finance"]; });
        return <Link to={"/DealIssues/Detail/All/DMV Finance/Open"} > {totalCount}</Link>;
    }

    onAfterMarketFooterCallback(rowData) {
        let totalCount = 0;
        this.state.tableData.forEach((rowData, index) => { totalCount += rowData.aftermarket; });
        return <Link to={"/DealIssues/Detail/All/Aftermarket/Open"}> {totalCount}</Link>;
    }

    onTitleFooterCallback(rowData) {
        let totalCount = 0;
        this.state.tableData.forEach((rowData, index) => { totalCount += rowData.title; });
        return <Link to={"/DealIssues/Detail/All/Title/Open"} > {totalCount}</Link>;
    }

    onOtherFooterCallback(rowData) {
        let totalCount = 0;
        this.state.tableData.forEach((rowData, index) => { totalCount += rowData.other; });
        return <Link to={"/DealIssues/Detail/All/Other/Open"} > {totalCount}</Link>;
    }

    onTotalFooterCallback(rowData) {
        let totalCount = 0;
        this.state.tableData.forEach((rowData, index) => { totalCount += rowData.total; });
        return <Link to={"/DealIssues/Detail/All/All/Open"}> {totalCount}</Link>;
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <DealIssuesSummaryQueryComponent
                applicationInsights={this.props.applicationInsights}
                isLoadingData={this.state.isLoadingData}
                onGetReportClick={this.onGetReportClick} />
            <div className="dgdg-site-scrollable-content">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable} showFooter
                    tablePageSize={CommonService.tablePageSize} stickyHeader
                    copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_web_name" filterColumn="store_web_name" sortColumn="store_number" footerText="Total" />
                        <DGDGTableColumnComponent headerText="Deal" dataColumn="deal" filterColumn="deal" sortColumn="deal" footerFunction="sum" dataColumnCallback={this.onDealCountColumnCallback} footerCssName="dgdg-deal-issues-summary-number-column" cssName="dgdg-deal-issues-summary-number-column" onFooterCallback={this.onDealFooterCallback} />
                        <DGDGTableColumnComponent headerText="Incentive" dataColumn="incentive" filterColumn="incentive" sortColumn="incentive" dataColumnCallback={this.onIncentiveCountColumnCallback} footerFunction="sum" footerCssName="dgdg-deal-issues-summary-number-column" cssName="dgdg-deal-issues-summary-number-column" onFooterCallback={this.onIncentiveFooterCallback} />
                        <DGDGTableColumnComponent headerText="DMV Service" dataColumn="dmv Service" filterColumn="dmv Service" sortColumn="dmv Service" dataColumnCallback={this.onDMVServiceCountColumnCallback} footerFunction="sum" footerCssName="dgdg-deal-issues-summary-number-column" cssName="dgdg-deal-issues-summary-number-column" onFooterCallback={this.onDMVServiceFooterCallback} />
                        <DGDGTableColumnComponent headerText="DMV Finance" dataColumn="dmv Finance" filterColumn="dmv Finance" sortColumn="dmv Finance" dataColumnCallback={this.onDMVFinanceCountColumnCallback} footerFunction="sum" footerCssName="dgdg-deal-issues-summary-number-column" cssName="dgdg-deal-issues-summary-number-column" onFooterCallback={this.onDMVFinanceFooterCallback} />
                        <DGDGTableColumnComponent headerText="Title" dataColumn="title" filterColumn="title" sortColumn="title" dataColumnCallback={this.onTitleCountColumnCallback} footerFunction="sum" footerCssName="dgdg-deal-issues-summary-number-column" cssName="dgdg-deal-issues-summary-number-column" onFooterCallback={this.onTitleFooterCallback} />
                        <DGDGTableColumnComponent headerText="Aftermarket" dataColumn="aftermarket" filterColumn="aftermarket" sortColumn="aftermarket" dataColumnCallback={this.onAfterMarketCountColumnCallback} footerFunction="sum" footerCssName="dgdg-deal-issues-summary-number-column" cssName="dgdg-deal-issues-summary-number-column" onFooterCallback={this.onAfterMarketFooterCallback} />
                        <DGDGTableColumnComponent headerText="Other" dataColumn="other" filterColumn="other" sortColumn="other" dataColumnCallback={this.onOtherCountColumnCallback} footerFunction="sum" footerCssName="dgdg-deal-issues-summary-number-column" cssName="dgdg-deal-issues-summary-number-column" onFooterCallback={this.onOtherFooterCallback} />
                        <DGDGTableColumnComponent headerText="Total" dataColumn="total" filterColumn="total" sortColumn="total" dataColumnCallback={this.onTotalColumnCallback} footerFunction="sum" footerCssName="dgdg-deal-issues-summary-number-column" cssName="dgdg-deal-issues-summary-number-column" onFooterCallback={this.onTotalFooterCallback} />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
        </div>;
    }
}
