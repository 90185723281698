import React from "react";
import { Link } from "react-router-dom";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableHeaderComponent } from "../common/dgdgtable/DGDGTableHeaderComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";

import { CITSummaryQueryComponent } from "./CITSummaryQueryComponent";

export class CITSummaryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            tableData: [],
            paintTable: false,
            days30PlusContractCount: null,
            days2130ContractCount: null,
            days1120ContractCount: null,
            days510ContractCount: null,
            totalContractCount: null,
            totalFundingAmount: null,
            agedPct: null,
            total1Text: null,
            total2Text: null,
            total3Text: null,
            total4Text: null,
            totalDealCount: null
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onContractCountColumnCallback = this.onContractCountColumnCallback.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "Contracts-In-Transit"

    componentDidMount() {
        try {
            console.devLog("CITSummaryComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/CIT/Summary", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("CITSummaryComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick() {
        try {
            this.setState({
                isLoadingData: true
            });

            this.props.applicationInsights.trackTrace({ message: "/CITSummaryComponent/onGetReportClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/CIT/GetSummaryReport", {
                method: "GET",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                }
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let tableData = parsedResponse.filter(tableRow => tableRow.store_number !== null);
                    let footerRow = parsedResponse.filter(tableRow => tableRow.store_number === null)[0];
                    let days30PlusContractCount = footerRow.days_30_contract_count ? footerRow.days_30_contract_count : "";
                    let days2130ContractCount = footerRow.days_21_contract_count ? footerRow.days_21_contract_count : "";
                    let days1120ContractCount = footerRow.days_11_contract_count ? footerRow.days_11_contract_count : "";
                    let days510ContractCount = footerRow.days_6_contract_count ? footerRow.days_6_contract_count : "";
                    let days14ContractCount = footerRow.days_1_contract_count ? footerRow.days_1_contract_count : "";
                    let totalContractCount = footerRow.total_contract_count ? footerRow.total_contract_count : "";
                    let totalFundingAmount = footerRow.total_funding_amount ? CommonService.formatCurrency(footerRow.total_funding_amount) : "";
                    let agedPct = footerRow.aged_pct ? CommonService.formatPercentage_1(footerRow.aged_pct) : "";
                    let total1Percentage = footerRow.days_30_funding_amount && footerRow.total_funding_amount ? (footerRow.days_30_funding_amount / footerRow.total_funding_amount) : "";
                    let total2Percentage = footerRow.days_21_funding_amount && footerRow.total_funding_amount ? (footerRow.days_21_funding_amount / footerRow.total_funding_amount) : "";
                    let total3Percentage = footerRow.days_11_funding_amount && footerRow.total_funding_amount ? (footerRow.days_11_funding_amount / footerRow.total_funding_amount) : "";
                    let total4Percentage = footerRow.days_6_funding_amount && footerRow.total_funding_amount ? (footerRow.days_6_funding_amount / footerRow.total_funding_amount) : "";
                    let total5Percentage = footerRow.days_1_funding_amount && footerRow.total_funding_amount ? (footerRow.days_1_funding_amount / footerRow.total_funding_amount) : "";

                    this.setState({
                        isLoadingData: false,
                        tableData: tableData,
                        days30PlusContractCount: days30PlusContractCount,
                        days2130ContractCount: days2130ContractCount,
                        days1120ContractCount: days1120ContractCount,
                        days510ContractCount: days510ContractCount,
                        days14ContractCount: days14ContractCount,
                        totalContractCount: totalContractCount,
                        totalFundingAmount: totalFundingAmount,
                        agedPct: agedPct,
                        total1Text: (footerRow.days_30_funding_amount ? CommonService.formatCurrency(footerRow.days_30_funding_amount) : "") + (total1Percentage ? " (" + CommonService.formatPercentage(total1Percentage) + ")" : ""),
                        total2Text: (footerRow.days_21_funding_amount ? CommonService.formatCurrency(footerRow.days_21_funding_amount) : "") + (total2Percentage ? " (" + CommonService.formatPercentage(total2Percentage) + ")" : ""),
                        total3Text: (footerRow.days_11_funding_amount ? CommonService.formatCurrency(footerRow.days_11_funding_amount) : "") + (total3Percentage ? " (" + CommonService.formatPercentage(total3Percentage) + ")" : ""),
                        total4Text: (footerRow.days_6_funding_amount ? CommonService.formatCurrency(footerRow.days_6_funding_amount) : "") + (total4Percentage ? " (" + CommonService.formatPercentage(total4Percentage) + ")" : ""),
                        total5Text: (footerRow.days_1_funding_amount ? CommonService.formatCurrency(footerRow.days_1_funding_amount) : "") + (total5Percentage ? " (" + CommonService.formatPercentage(total5Percentage) + ")" : ""),
                        totalDealCount: CommonService.formatNumber(footerRow.deal_count),
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/CIT/GetSummaryReport", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("CITSummaryComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onContractCountColumnCallback(rowData, columnName) {
        return <Link to={"/CIT/Detail/" + rowData.store_number}> {rowData[columnName]}</Link>;
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <CITSummaryQueryComponent applicationInsights={this.props.applicationInsights} isLoadingData={this.state.isLoadingData} onGetReportClick={this.onGetReportClick} />
            <div className="dgdg-site-scrollable-content">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText="Summary" tableData={this.state.tableData} paintTable={this.state.paintTable} showFooter
                    copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableHeaderComponent position="PostHeader">
                        <tr className="dgdg-table-v3-header">
                            <td />
                            <td colSpan="2" className="text-center font-weight-bold dgdg-cit-summary-gt-30">30+ Days</td>
                            <td colSpan="2" className="text-center font-weight-bold dgdg-cit-summary-gt-20">21-30 Days</td>
                            <td colSpan="2" className="text-center font-weight-bold dgdg-cit-summary-gt-10">11-20 Days</td>
                            <td colSpan="2" className="text-center font-weight-bold dgdg-cit-summary-gt-5">6-10 Days</td>
                            <td colSpan="2" className="text-center font-weight-bold">1-5 Days</td>
                            <td colSpan="2" className="text-center font-weight-bold dgdg-cit-summary-total">Total</td>
                            <td />
                            <td />
                        </tr>
                    </DGDGTableHeaderComponent>
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="Store" dataColumn="store_web_name" sortColumn="store_number" filterColumn="store_web_name" footerText="Total" />
                        <DGDGTableColumnComponent headerText="Contracts" dataColumn="days_30_contract_count" filterColumn="days_30_contract_count" sortColumn="days_30_contract_count" dataType="number" footerCssName="text-right" footerText={this.state.days30PlusContractCount} dataColumnCallback={this.onContractCountColumnCallback} />
                        <DGDGTableColumnComponent headerText="Total" dataColumn="days_30_funding_amount" sortColumn="days_30_funding_amount" filterColumn="days_30_funding_amount" dataType="money_2_simple" footerCssName="text-right" footerText={this.state.total1Text} />
                        <DGDGTableColumnComponent headerText="Contracts" dataColumn="days_21_contract_count" sortColumn="days_21_contract_count" filterColumn="days_21_contract_count" dataType="number" footerCssName="text-right" footerText={this.state.days2130ContractCount} dataColumnCallback={this.onContractCountColumnCallback} />
                        <DGDGTableColumnComponent headerText="Total" dataColumn="days_21_funding_amount" sortColumn="days_21_funding_amount" filterColumn="days_21_funding_amount" dataType="money_2_simple" footerCssName="text-right" footerText={this.state.total2Text} />
                        <DGDGTableColumnComponent headerText="Contracts" dataColumn="days_11_contract_count" sortColumn="days_11_contract_count" filterColumn="days_11_contract_count" dataType="number" footerCssName="text-right" footerText={this.state.days1120ContractCount} dataColumnCallback={this.onContractCountColumnCallback} />
                        <DGDGTableColumnComponent headerText="Total" dataColumn="days_11_funding_amount" sortColumn="days_11_funding_amount" filterColumn="days_11_funding_amount" dataType="money_2_simple" footerCssName="text-right" footerText={this.state.total3Text} />
                        <DGDGTableColumnComponent headerText="Contracts" dataColumn="days_6_contract_count" sortColumn="days_6_contract_count" filterColumn="days_6_contract_count" dataType="number" footerCssName="text-right" footerText={this.state.days510ContractCount} dataColumnCallback={this.onContractCountColumnCallback} />
                        <DGDGTableColumnComponent headerText="Total" dataColumn="days_6_funding_amount" sortColumn="days_6_funding_amount" filterColumn="days_6_funding_amount" dataType="money_2_simple" footerCssName="text-right" footerText={this.state.total4Text} />
                        <DGDGTableColumnComponent headerText="Contracts" dataColumn="days_1_contract_count" sortColumn="days_1_contract_count" filterColumn="days_1_contract_count" dataType="number" footerCssName="text-right" footerText={this.state.days14ContractCount} dataColumnCallback={this.onContractCountColumnCallback} />
                        <DGDGTableColumnComponent headerText="Total" dataColumn="days_1_funding_amount" sortColumn="days_1_funding_amount" filterColumn="days_1_funding_amount" dataType="money_2_simple" footerCssName="text-right" footerText={this.state.total5Text} />
                        <DGDGTableColumnComponent headerText="Contracts" dataColumn="total_contract_count" sortColumn="total_contract_count" filterColumn="total_contract_count" dataType="number" footerCssName="text-right" footerText={this.state.totalContractCount} dataColumnCallback={this.onContractCountColumnCallback} />
                        <DGDGTableColumnComponent headerText="Total" dataColumn="total_funding_amount" sortColumn="total_funding_amount" filterColumn="total_funding_amount" dataType="money_2_simple" footerCssName="text-right" footerText={this.state.totalFundingAmount} />
                        <DGDGTableColumnComponent headerText="% Aged Over 10 Days" dataColumn="aged_pct" sortColumn="aged_pct" filterColumn="aged_pct" dataType="percent_1" footerCssName="text-right" columnHelp="Percentage of contracts 11 days and older to deals in the last 30 days" footerText={this.state.agedPct} />
                        <DGDGTableColumnComponent headerText="Deals" dataColumn="deal_count" sortColumn="deal_count" filterColumn="deal_count" dataType="number" footerCssName="text-right" columnHelp="Deals in the last 30 days" footerText={this.state.totalDealCount} />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
        </div>;
    }
}
