import React from "react";
import moment from "moment";

import { CommonService } from "../../js_modules/dgdgjs/CommonService";

export class DGDGFormMultiSelectComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectAll: true,
            displayText: "Select",
        };

        this.onChange = this.onChange.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (prevProps.value === null && this.props.value !== null) {
                if (Array.isArray(this.props.value)
                    && this.props.value.length > 0) {
                    let selectedValues = this.props.value;
                    if (this.props.itemKey && this.props.value.length !== this.props.data.length) {
                        this.props.data.forEach(item => {
                            if (selectedValues.some(value => value[this.props.itemKey] === item[this.props.itemKey])) {
                                item.checked = true;
                            }
                            else {
                                item.checked = false;
                            }

                        });
                    }
                    this.setState({
                        selectAll: !this.props.data.some(item => item.checked === false)
                    });
                    this.handleDisplayText();
                }
            }
            if (prevProps.data === null && this.props.data !== null) {
                this.handleDisplayText();
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    formatData(data) {
        let value = "";
        if (data) {
            switch (this.props.displayFormat) {
                case "monthYear":
                    value = CommonService.formatMonthYear(moment(data));
                    break;

                case "Year":
                    value = CommonService.formatYear(moment(data));
                    break;

                default:
                    value = data;
                    break;
            }
        }

        return value;
    }

    onChange(event, item) {
        item.checked = event.target.checked;
        if (this.props.onChange) {
            this.props.onChange(this.props.id, [item]);
        }
        this.handleDisplayText();
        this.setState({
            selectAll: !this.props.data.some(item => item.checked === false)
        });
    }

    handleDisplayText() {
        let displayText = this.state.displayText;
        if (this.props.nonSelectedText) {
            displayText = this.props.nonSelectedText;
        }

        if (this.props.data) {
            if (Array.isArray(this.props.data)
                && this.props.data.length > 0
            ) {
                let selectedItems = this.props.data.filter(x => x.checked === true);
                if (selectedItems && selectedItems.length > 0) {
                    if (selectedItems.length === 1) {
                        let item = selectedItems[0];
                        if (item) {
                            displayText = this.props.itemKey
                                ? this.formatData(item[this.props.itemKey])
                                : this.formatData(item);
                        }
                    } else if (selectedItems.length === this.props.data.length) {
                        displayText = (this.props?.allSelectedText !== "") ? this.props.allSelectedText : "";
                    } else {
                        displayText = (this.props?.multipleSelectedText !== "") ? this.props.multipleSelectedText : "";
                    }
                } else if (this.state.selectAll === true) {
                    displayText = (this.props?.allSelectedText !== "") ? this.props.allSelectedText : "";
                }
            }
        }
        if (displayText !== "" && displayText !== this.state.displayText) {
            this.setState({
                displayText: displayText
            });
        }
    }

    onSelectAll(event) {
        let selectAll = !this.state.selectAll;
        this.props.data.forEach(item => {
            item.checked = selectAll;
        });
        this.setState({ selectAll: !this.state.selectAll });
        this.props.onChange(this.props.id, this.props.data);
        this.handleDisplayText();
    }

    render() {
        let colSpan = this.props.colSpan ? this.props.colSpan : "col-form-label";
        let labelSpan = this.props.labelSpan ? this.props.labelSpan : "col-form-label dgdg-form-label";
        let controlSpan = this.props.controlSpan ? this.props.controlSpan : "col-form-label";
        if (this.props.cssName) {
            colSpan += " " + this.props.cssName;
        }
        if (this.props.controlCssName) {
            controlSpan += " " + this.props.controlCssName;
        }

        let ddlElements = [];
        if (this.props.data) {
            ddlElements.push(<div key={this.props.id + "_selectAll"} className="d-flex btn btn-link dropdown-item dgdg-multiselect-item">
                <input id={this.props.id + "_ddlMultiSelect_selectAll"} disabled={this.props.disabled} checked={this.state.selectAll} className="dgdg-multiselect" onChange={(event) => this.onSelectAll(event)} type="checkbox" />
                <label htmlFor={this.props.id + "_ddlMultiSelect_selectAll"}>Select All</label>
            </div>
            );

            this.props.data.forEach((item, index) => {
                ddlElements.push(<div key={this.props.id + index} className="d-flex btn btn-link dropdown-item dgdg-multiselect-item">
                    <input id={this.props.id + "_ddlMultiSelect_" + index} disabled={this.props.disabled} checked={item.checked !== false ? true : false} className="dgdg-multiselect" onChange={(event) => this.onChange(event, item)} type="checkbox" />
                    <label htmlFor={this.props.id + "_ddlMultiSelect_" + index}>
                        {
                            this.props.itemKey
                                ? this.formatData(item[this.props.itemKey])
                                : this.formatData(item)
                        }
                    </label>
                </div>
                );
            });
        }

        let txtStyle = {
            "cursor": "pointer",
            "zIndex": "100"
        };

        if (!this.props.disabled) {
            txtStyle.backgroundColor = "white";
        }

        return <div className={"form-group " + colSpan}>
            {
                this.props.label
                    ? <label className={"dgdg-font-blue " + labelSpan}>
                        {this.props.label}:
                        {
                            this.props.isRequired
                                ? <span className="text-danger">*</span>
                                : null
                        }
                    </label>
                    : null
            }

            <div className={controlSpan + " " + (this.props.dropDownCss ?? " ")}>
                <div className="dropdown">
                    <div className="input-group" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <input className={"form-control" + (this.props.inputCssName ? " " + this.props.inputCssName : "")} value={this.state.displayText} style={txtStyle} disabled={this.props.disabled} tabIndex="-1" readOnly />
                        <span className="input-group-append">
                            <button className="btn btn-secondary dropdown-toggle" type="button" disabled={this.props.disabled} style={{ "zIndex": "100" }} />
                        </span>
                    </div>
                    <div className="dropdown-menu dgdg-multi-select" hidden={this.props.disabled}>
                        {ddlElements}
                    </div>
                </div>
            </div>
        </div>;
    }
}
