import React, { Fragment } from "react";
import moment from "moment";
import $ from "jquery";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormTextAreaComponent } from "../common/dgdgtextarea/DGDGFormTextAreaComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableColumnDropdownComponent } from "../common/dgdgtable/DGDGTableColumnDropdownComponent";
import { DGDGFormCalendarComponent } from "../common/dgdgcalendar/DGDGFormCalendarComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { OpenROsQueryComponent } from "./OpenROsQueryComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

export class OpenROsComponent extends React.Component {

    statusData = [
        { key: "In Progress", value: "In Progress" },
        { key: "Holding Parts", value: "Holding Parts" },
        { key: "Holding Parts - Backorder", value: "Holding Parts - Backorder" },
        { key: "Holding Authorization", value: "Holding Authorization" },
        { key: "Other", value: "Other" },
        { key: "Completed", value: "Completed" }
    ];

    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            paintTable: false,
            hideCompleted: false,
            tableTitle: "",
            tableData: [],
            legendText: null
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onAddCommentColumnCallback = this.onAddCommentColumnCallback.bind(this);
        this.onToggleViewComments = this.onToggleViewComments.bind(this);
        this.onToggleAddComments = this.onToggleAddComments.bind(this);
        this.onCommentTextChange = this.onCommentTextChange.bind(this);
        this.onSaveCommentClick = this.onSaveCommentClick.bind(this);
        this.onSaveEstCompletionDateClick = this.onSaveEstCompletionDateClick.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.onEstCompletionDateColumnCallback = this.onEstCompletionDateColumnCallback.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onHideCompletedChange = this.onHideCompletedChange.bind(this);
        this.roNumberColumnCallback = this.roNumberColumnCallback.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "Open ROs";

    componentDidMount() {
        try {
            console.devLog("OpenROsComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/OpenROs/OpenROs", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.onGetLastUpdatedDate();
            this.getLegendText();
            CommonService.clientAzureStorageLog("OpenROsComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    componentWillUnmount() {
        this.props.onLastUpdatedDateChanged("");
    }

    getLegendText() {
        let legendText = <Fragment>
            <div className="d-inline-block text-nowrap"><div className="d-inline dgdg-import-wizard-legend dgdg-open-ro-status-in-process" />&nbsp;&nbsp;&nbsp;In Progress</div>
            <div className="d-inline-block text-nowrap"><div className="d-inline dgdg-import-wizard-legend dgdg-open-ro-status-holding-parts" />&nbsp;&nbsp;&nbsp;Holding Parts</div>
            <div className="d-inline-block text-nowrap"><div className="d-inline dgdg-import-wizard-legend dgdg-open-ro-status-holding-parts-backorder" />&nbsp;&nbsp;&nbsp;Holding Parts - Backorder</div>
            <div className="d-inline-block text-nowrap"><div className="d-inline dgdg-import-wizard-legend dgdg-open-ro-status-holding-authorization" />&nbsp;&nbsp;&nbsp;Holding Authorization</div>
            <div className="d-inline-block text-nowrap"><div className="d-inline dgdg-import-wizard-legend dgdg-open-ro-status-other" />&nbsp;&nbsp;&nbsp;Other</div>
            <div className="d-inline-block text-nowrap"><div className="d-inline dgdg-import-wizard-legend dgdg-open-ro-status-completed" />&nbsp;&nbsp;&nbsp;Completed</div>
            <div className="d-inline-block text-nowrap"><div className="d-inline dgdg-import-wizard-legend dgdg-open-ro-doc" />&nbsp;&nbsp;&nbsp;Past EDOC</div>
        </Fragment>
        this.setState({ legendText: legendText })
    }

    onGetReportClick(selectedStore) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                storeNumber: selectedStore.store_number,
                hideCompleted: this.state.hideCompleted
            };
            this.props.applicationInsights.trackTrace({ message: "/OpenROsComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/OpenROs/GetReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let tableData = parsedResponse;

                    tableData.forEach((rowData) => {
                        rowData.showViewComments = true;
                        rowData.is_status_disabled = !rowData.edit_status;
                        rowData.est_completion_date = rowData.est_completion_date ? moment(rowData.est_completion_date) : null;
                        this.setRowColor(rowData);
                    });

                    this.setState({
                        isLoadingData: false,
                        tableTitle: selectedStore.display_name,
                        tableData: CommonService.addOrdinalColumn(tableData),
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableTitle: "",
                        tableData: [],
                        paintTable: true,
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/OpenROs/GetReport", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAddCommentColumnCallback(rowData) {
        let comments = this.getComments(rowData.comments);
        return <Fragment>
            <button key="btnViewComments" type="button" className="btn btn-link" disabled={!rowData.status} onClick={(event) => this.onToggleViewComments(event, rowData)}>{comments.length / 2} Comments
                {
                    rowData.showViewComments
                        ? <span className="far fa-sort-up dgdg-icon" />
                        : <span className="far fa-sort-down dgdg-icon" />
                }
            </button>
            <button key="btnAddComment" type="button" className="btn btn-link dgdg-icon" disabled={!rowData.status || !rowData.edit_comment} onClick={(event) => this.onToggleAddComments(event, rowData)}>Add Comments
                {
                    rowData.showAddComment
                        ? <span className="fas fa-times text-danger dgdg-icon" />
                        : <span className="fas fa-plus dgdg-icon" />
                }
            </button>
            <br />
            {
                rowData.showViewComments
                    ? comments
                    : null
            }
            {
                rowData.showAddComment
                    ? <div className="form-inline">
                        <DGDGFormTextAreaComponent id={"comments_" + rowData.vin} value={rowData["comments_" + rowData.vin]} rows="3" onChange={this.onCommentTextChange} colSpan="col-11" controlSpan="col-12" />
                        <button type="button" className="btn btn-link dgdg-font-size-18" disabled={!rowData.edit_comment} onClick={(event) => this.onSaveCommentClick(event, rowData)}>
                            <span className="fas fa-save" />
                        </button>
                        <DGDGSpinnerComponent showSpinner={rowData.comments_showSpinner} />
                    </div>
                    : null
            }
        </Fragment>;
    }

    onToggleViewComments(event, rowData) {
        let tableData = this.state.tableData;
        rowData.showViewComments = rowData.showViewComments ? false : true;
        this.setState({
            tableData: tableData,
            paintTable: true
        }, () => {
            this.setState({ paintTable: false });
        });
    }

    onToggleAddComments(event, rowData) {
        let tableData = this.state.tableData;
        rowData.showAddComment = rowData.showAddComment ? false : true;
        this.setState({
            tableData: tableData,
            paintTable: true
        }, () => {
            this.setState({ paintTable: false });
            if (rowData.showAddComment) {
                let id = "#comments_" + rowData.vin;
                $(id).focus();
            }
        });
    }

    onCommentTextChange(id, value) {
        try {
            let tableData = this.state.tableData;
            let rowData = tableData.filter((row) => row.vin === id.split('_')[1])[0];
            rowData["comments_" + rowData.vin] = value;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStatusChange(id, selectedItem, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.status = selectedItem.key;
            rowData.status_showSpinner = true;
            this.setRowColor(rowData);
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
            let inputData = {
                storeNumber: rowData.store_number,
                roNumber: rowData.ro_number,
                vehicleId: rowData.vehicle_id,
                status: rowData.status
            };
            this.props.applicationInsights.trackTrace({ message: "/OpenROsComponent/onStatusChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/OpenROs/SaveOpenROStatus", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    rowData.status_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.status_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/OpenROs/SaveOpenROStatus", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDateChange(id, value, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.est_completion_date = value ? moment(value) : null;
            this.setRowColor(rowData);
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveEstCompletionDateClick(event, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.est_completion_date_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
            let inputData = {
                storeNumber: rowData.store_number,
                roNumber: rowData.ro_number,
                vehicleId: rowData.vehicle_id,
                estimatedCompletionDate: rowData.est_completion_date ? CommonService.formatDate(rowData.est_completion_date) : null
            };
            this.props.applicationInsights.trackTrace({ message: "/OpenROsComponent/onSaveEstCompletionDateClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/OpenROs/SaveOpenROEstimatedCompletionDate", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    rowData.est_completion_by = this.props.remoteUpn;
                    rowData.est_completion_date_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.est_completion_date_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/OpenROs/SaveOpenROEstimatedCompletionDate", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveCommentClick(event, rowData) {
        try {
            let tableData = this.state.tableData;
            if (rowData["comments_" + rowData.vin]) {
                rowData.comments_showSpinner = true;
                this.setState({
                    tableData: tableData,
                    paintTable: true
                }, () => {
                    this.setState({ paintTable: false });
                });
                let inputData = {
                    storeNumber: rowData.store_number,
                    roNumber: rowData.ro_number,
                    vehicleId: rowData.vehicle_id,
                    comments: rowData["comments_" + rowData.vin].replace(/\n|\r/g, " ")
                };
                this.props.applicationInsights.trackTrace({ message: "/OpenROsComponent/onSaveCommentClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                fetch("/OpenROs/SaveOpenROComments", {
                    method: "POST",
                    headers: {
                        'Content-Type': "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData)
                })
                    .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                    .then(parsedResponse => {
                        rowData.comments = moment().format("MM-DD-YYYY hh:mm A") + ", " + this.props.remoteUpn + ": " + rowData["comments_" + rowData.vin] + (rowData.comments ? '\r' + rowData.comments : "");
                        rowData["comments_" + rowData.vin] = "";
                        rowData.comments_showSpinner = false;
                        this.setState({
                            tableData: tableData,
                            paintTable: true
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                    })
                    .catch(notOKResponse => {
                        rowData.comments_showSpinner = false;
                        this.setState({
                            tableData: tableData,
                            paintTable: true
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/OpenROs/SaveOpenROComments", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                    console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                    this.props.onShowAlert("danger", parsedError);
                                })
                                .catch(jsonParseError => {
                                    console.devError(jsonParseError, null, this.props.applicationInsights);
                                });
                        }
                    });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getComments(comments) {
        let htmlContent = [];
        if (comments !== null) {
            let commentParts = [];
            comments.split('\r').forEach(function (value) {
                commentParts.push(value.split(': '));
            });
            commentParts.forEach((commentPart, rowIndex) => {
                if (commentPart[1]) {
                    htmlContent.push(
                        <Fragment key={"comment_" + rowIndex}>
                            <span key="comment_part1" style={{ "color": "blue" }}>
                                {(commentPart[0] !== "" ? commentPart[0] + ": " : null)}
                            </span>
                            <span key="comment_part2">
                                {(commentPart[1])}
                            </span>
                        </Fragment>);
                    htmlContent.push(<br />);
                }
            });
        }

        return htmlContent;
    }

    roNumberColumnCallback(rowData) {
        return <a href={"/RO/OpenRO/Detail/" + rowData.store_number + "/" + rowData.ro_number + "/" + rowData.vehicle_id} target="_self"> {rowData.ro_number}</a>;
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("OpenROsComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    setRowColor(rowData) {
        switch (rowData.status) {
            case "Holding Parts":
                rowData.rowCssName = "dgdg-open-ro-status-holding-parts";
                break;
            case "Holding Parts - Backorder":
                rowData.rowCssName = "dgdg-open-ro-status-holding-parts-backorder";
                break;
            case "Holding Authorization":
                rowData.rowCssName = "dgdg-open-ro-status-holding-authorization";
                break;
            case "Other":
                rowData.rowCssName = "dgdg-open-ro-status-other";
                break;
            case "Completed":
                rowData.rowCssName = "dgdg-open-ro-status-completed";
                break;
            default:
                rowData.rowCssName = "dgdg-open-ro-status-in-process";
                break;
        }
    }

    onEstCompletionDateColumnCallback(rowData) {
        let minDate = 'today';
        let disabledDates = [];
        if (rowData.est_completion_date && rowData.est_completion_date < moment().subtract(1, "day")) {
            minDate = moment(rowData.est_completion_date).format('MM/DD/YYYY');
            let currentDate = moment(rowData.est_completion_date).add(1, "day");
            while (currentDate < moment().subtract(1, "day")) {
                disabledDates.push(currentDate.format('M/D/YYYY'));
                currentDate = currentDate.add(1, "day");
            }
            rowData.est_completion_date_cellCssName = "dgdg-open-ro-doc";
        }
        else {
            rowData.est_completion_date_cellCssName = "";
        }

        let isDateInvalidorEmpty = rowData.est_completion_date === null || !CommonService.isDateValid(rowData.est_completion_date);

        return <Fragment>
            <div className="d-inline-flex">
                <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id={rowData.dgdg_column_ordinal + "_estColumn"} value={rowData.est_completion_date} minDate={minDate} disabled={!rowData.status || !rowData.edit_doc} onDateChange={this.onDateChange} additionalData={rowData} disabledDates={disabledDates} />
                <button type="button" className="btn btn-link dgdg-font-size-18" disabled={!rowData.status || !rowData.edit_doc || isDateInvalidorEmpty} onClick={(event) => this.onSaveEstCompletionDateClick(event, rowData)}>
                    <span className="fas fa-save" />
                </button>
            </div>
            <br />
            <span> {rowData.est_completion_by ? ("Updated by: " + rowData.est_completion_by) : ""} </span>
        </Fragment>
    }

    onHideCompletedChange(isChecked) {
        this.setState({ hideCompleted: isChecked });
    }

    onGetLastUpdatedDate() {
        try {
            this.props.applicationInsights.trackTrace({ message: "/OpenROsComponent/onGetLastUpdatedDate", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/OpenROs/GetLastUpdated")
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (CommonService.isDateValid(parsedResponse)) {
                        this.props.onLastUpdatedDateChanged(moment.utc(parsedResponse));
                    }
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/OpenROsComponent/onGetLastUpdatedDate", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <OpenROsQueryComponent
                applicationInsights={this.props.applicationInsights}
                stores={this.props.stores}
                isLoadingData={this.state.isLoadingData}
                onHideCompletedChange={this.onHideCompletedChange}
                onGetReportClick={this.onGetReportClick} />
            <div className="dgdg-site-scrollable-content">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable}
                    tablePageSize={CommonService.tablePageSize} stickyHeader legendText={this.state.legendText}
                    copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <DGDGTableColumnDropdownComponent headerText="Status" dataColumn="status" filterColumn="status" sortColumn="status" data={this.statusData} itemKey="value" disabledColumn="is_status_disabled" placeholder="Select Status" headerCssName="dgdg-open-ro-status-col" onItemClick={this.onStatusChange} showSpinner />
                        <DGDGTableColumnComponent headerText="RO Number" dataColumn="ro_number" filterColumn="ro_number" sortColumn="ro_number" dataColumnCallback={this.roNumberColumnCallback} />
                        <DGDGTableColumnComponent headerText="Customer Name" dataColumn="customer_name" filterColumn="customer_name" sortColumn="customer_name" />
                        <DGDGTableColumnComponent headerText="VIN" dataColumn="vin" filterColumn="vin" sortColumn="vin" />
                        <DGDGTableColumnComponent headerText="Tag #" dataColumn="tag" sortColumn="tag" filterColumn="tag" />
                        <DGDGTableColumnComponent headerText="Make" dataColumn="make" sortColumn="make" filterColumn="make" />
                        <DGDGTableColumnComponent headerText="Year" dataColumn="year" sortColumn="year" filterColumn="year" />
                        <DGDGTableColumnComponent headerText="Model" dataColumn="model" sortColumn="model" filterColumn="model" />
                        <DGDGTableColumnComponent headerText="Open Date" dataColumn="open_date" sortColumn="open_date" filterColumn="open_date" dataType="date" />
                        <DGDGTableColumnComponent headerText="Days In Shop" dataColumn="days_in_shop" filterColumn="days_in_shop" dataType="number" sortColumn="days_in_shop" />
                        <DGDGTableColumnComponent headerText="Estimated Date of Completion" dataColumnCallback={this.onEstCompletionDateColumnCallback} dataColumn="est_completion_date" filterColumn="est_completion_date" sortColumn="est_completion_date" showSpinner />
                        <DGDGTableColumnComponent headerText="RO Status" dataColumn="ro_status_desc" filterColumn="ro_status_desc" sortColumn="ro_status_desc" />
                        <DGDGTableColumnComponent headerText="Service Advisor" dataColumn="service_advisor_name" filterColumn="service_advisor_name" sortColumn="service_advisor_name" />
                    </DGDGTableRowComponent>
                    <DGDGTableRowComponent>
                        <DGDGTableColumnComponent colSpan="8" dataColumnCallback={this.onAddCommentColumnCallback} />
                        <DGDGTableColumnComponent colSpan="6" />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
        </div>;
    }
}
