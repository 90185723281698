import React from "react";
import { Link } from "react-router-dom";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGTableHeaderComponent } from "../common/dgdgtable/DGDGTableHeaderComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";

import { BadDebtSummaryQueryComponent } from "./BadDebtSummaryQueryComponent";

export class BadDebtSummaryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            tableData: [],
            paintTable: false,
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onIssuesColumnCallback = this.onIssuesColumnCallback.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "Bad Debts"

    componentDidMount() {
        try {
            console.devLog("BadDebtSummaryComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/BadDebt/Summary", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("BadDebtSummaryComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick() {
        try {
            this.setState({
                isLoadingData: true
            });
            this.props.applicationInsights.trackTrace({ message: "/BadDebtSummaryComponent/onGetReportClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/BadDebt/GetSummaryReport", {
                method: "GET",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                }
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let tableData = parsedResponse.filter(tableRow => tableRow.store_number !== null);

                    this.setState({
                        isLoadingData: false,
                        tableData: CommonService.addOrdinalColumn(tableData),
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/BadDebt/GetSummaryReport", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("BadDebtSummaryComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onIssuesColumnCallback(rowData, columnName) {
        return <Link to={"/BadDebt/Detail/" + rowData.store_number}> {rowData[columnName]}</Link>;
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <BadDebtSummaryQueryComponent applicationInsights={this.props.applicationInsights} isLoadingData={this.state.isLoadingData} onGetReportClick={this.onGetReportClick} />
            <div className="dgdg-site-scrollable-content">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText="Summary" tableData={this.state.tableData} paintTable={this.state.paintTable} showFooter
                    copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <DGDGTableHeaderComponent position="PreHeader">
                        <tr className="dgdg-table-v3-header">
                            <td />
                            <td colSpan="8" className="text-center font-weight-bold dgdg-bad-debt-status-bg-head">NSF</td>
                            <td colSpan="6" className="text-center font-weight-bold dgdg-bad-debt-status-bg-head">First Payment</td>
                            <td colSpan="2" className="text-center font-weight-bold dgdg-bad-debt-status-bg-head">Service NSF</td>
                            <td colSpan="2" className="text-center font-weight-bold dgdg-bad-debt-status-bg-head">Other</td>
                        </tr>
                    </DGDGTableHeaderComponent>
                    <DGDGTableHeaderComponent position="PreHeader">
                        <tr className="dgdg-table-v3-header">
                            <td />
                            <td colSpan="2" className="text-center font-weight-bold dgdg-bad-debt-status-bg-other-le-60">NSFs 0-30 Days</td>
                            <td colSpan="2" className="text-center font-weight-bold dgdg-bad-debt-status-bg-other-le-60">NSFs 31-60 Days</td>
                            <td colSpan="2" className="text-center font-weight-bold dgdg-bad-debt-status-bg-other-gt-60">NSFs 60+ Days</td>
                            <td colSpan="2" className="text-center font-weight-bold dgdg-bad-debt-status-bg-total dgdg-font-white">Total NSFs</td>
                            <td colSpan="2" className="text-center font-weight-bold dgdg-bad-debt-status-bg-other-le-60">First Payment 0-60 Days</td>
                            <td colSpan="2" className="text-center font-weight-bold dgdg-bad-debt-status-bg-other-gt-60">First Payment 60+ Days</td>
                            <td colSpan="2" className="text-center font-weight-bold dgdg-bad-debt-status-bg-total dgdg-font-white">Total First Payment</td>
                            <td colSpan="2" className="text-center font-weight-bold dgdg-bad-debt-status-bg-total-service-nsf">Total Service NSF </td>
                            <td colSpan="2" className="text-center font-weight-bold dgdg-bad-debt-status-bg-other">Other/Unknown Debt</td>
                        </tr>
                    </DGDGTableHeaderComponent>
                    <DGDGTableRowComponent hasHeader>
                        <DGDGTableColumnComponent headerText="Dealership" dataColumn="store_name" sortColumn="store_name" filterColumn="store_name" footerText="Total" />
                        <DGDGTableColumnComponent headerText="# of Issues" dataColumn="nsf_issues_0_30" filterColumn="nsf_issues_0_30" sortColumn="nsf_issues_0_30" dataType="number" footerCssName="text-right" footerFunction="sum" dataColumnCallback={this.onIssuesColumnCallback} />
                        <DGDGTableColumnComponent headerText="Total $" dataColumn="nsf_amount_0_30" sortColumn="nsf_amount_0_30" filterColumn="nsf_amount_0_30" dataType="money_2_simple" footerCssName="text-right" footerFunction="sum" />
                        <DGDGTableColumnComponent headerText="# of Issues" dataColumn="nsf_issues_31_60" sortColumn="nsf_issues_31_60" filterColumn="nsf_issues_31_60" dataType="number" footerCssName="text-right" footerFunction="sum" dataColumnCallback={this.onIssuesColumnCallback} />
                        <DGDGTableColumnComponent headerText="Total $" dataColumn="nsf_amount_31_60" sortColumn="nsf_amount_31_60" filterColumn="nsf_amount_31_60" dataType="money_2_simple" footerCssName="text-right" footerFunction="sum" />
                        <DGDGTableColumnComponent headerText="# of Issues" dataColumn="nsf_issues_60" sortColumn="nsf_issues_60" filterColumn="nsf_issues_60" dataType="number" footerCssName="text-right" footerFunction="sum" dataColumnCallback={this.onIssuesColumnCallback} />
                        <DGDGTableColumnComponent headerText="Total $" dataColumn="nsf_amount_60" sortColumn="nsf_amount_60" filterColumn="nsf_amount_60" dataType="money_2_simple" footerCssName="text-right" footerFunction="sum" />
                        <DGDGTableColumnComponent headerText="# of Issues" dataColumn="nsf_issues_total" sortColumn="nsf_issues_total" filterColumn="nsf_issues_total" dataType="number" footerCssName="text-right" footerFunction="sum" dataColumnCallback={this.onIssuesColumnCallback} />
                        <DGDGTableColumnComponent headerText="Total $" dataColumn="nsf_amount_total" sortColumn="nsf_amount_total" filterColumn="nsf_amount_total" dataType="money_2_simple" footerCssName="text-right" footerFunction="sum" />
                        <DGDGTableColumnComponent headerText="# of Issues" dataColumn="uncollected_issues_0_60" sortColumn="uncollected_issues_0_60" filterColumn="uncollected_issues_0_60" dataType="number" footerCssName="text-right" footerFunction="sum" dataColumnCallback={this.onIssuesColumnCallback} />
                        <DGDGTableColumnComponent headerText="Total $" dataColumn="uncollected_amount_0_60" sortColumn="uncollected_amount_0_60" filterColumn="uncollected_amount_0_60" dataType="money_2_simple" footerCssName="text-right" footerFunction="sum" />
                        <DGDGTableColumnComponent headerText="# of Issues" dataColumn="uncollected_issues_60" sortColumn="uncollected_issues_60" filterColumn="uncollected_issues_60" dataType="number" footerCssName="text-right" footerFunction="sum" dataColumnCallback={this.onIssuesColumnCallback} />
                        <DGDGTableColumnComponent headerText="Total $" dataColumn="uncollected_amount_60" sortColumn="uncollected_amount_60" filterColumn="uncollected_amount_60" dataType="money_2_simple" footerCssName="text-right" footerFunction="sum" />
                        <DGDGTableColumnComponent headerText="# of Issues" dataColumn="uncollected_issues_total" sortColumn="uncollected_issues_total" filterColumn="uncollected_issues_total" dataType="number" footerCssName="text-right" footerFunction="sum" dataColumnCallback={this.onIssuesColumnCallback} />
                        <DGDGTableColumnComponent headerText="Total $" dataColumn="uncollected_amount_total" sortColumn="uncollected_amount_total" filterColumn="uncollected_amount_total" dataType="money_2_simple" footerCssName="text-right" footerFunction="sum" />
                        <DGDGTableColumnComponent headerText="# of Issues" dataColumn="service_nsf_issues_total" sortColumn="service_nsf_issues_total" filterColumn="service_nsf_issues_total" dataType="number" footerCssName="text-right" footerFunction="sum" dataColumnCallback={this.onIssuesColumnCallback} />
                        <DGDGTableColumnComponent headerText="Total $" dataColumn="service_nsf_amount_total" sortColumn="service_nsf_amount_total" filterColumn="service_nsf_amount_total" dataType="money_2_simple" footerCssName="text-right" footerFunction="sum" />
                        <DGDGTableColumnComponent headerText="# of Issues" dataColumn="others_issues_total" sortColumn="others_issues_total" filterColumn="others_issues_total" dataType="number" footerCssName="text-right" footerFunction="sum" dataColumnCallback={this.onIssuesColumnCallback} />
                        <DGDGTableColumnComponent headerText="Total $" dataColumn="others_amount_total" sortColumn="others_amount_total" filterColumn="others_amount_total" dataType="money_2_simple" footerCssName="text-right" footerFunction="sum" />
                    </DGDGTableRowComponent>
                </DGDGTableV3Component>
            </div>
        </div>;
    }
}
