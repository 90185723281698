import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { UsedReconditioningSummaryQueryComponent } from "./UsedReconditioningSummaryQueryComponent";
import { UsedReconditioningChartComponent } from "./UsedReconditioningChartComponent";
import { UsedReconditioningChartColumnComponent } from "./UsedReconditioningChartColumnComponent";
import { UsedReconditioningSummaryDetailsComponent } from "./UsedReconditioningSummaryDetailsComponent";

export class UsedReconditioningSummaryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            selectedStore: null,
            selectedMonth: null,
            frontLineReadyData: null,
            columnChartData: null,
            columnChartTitle: "Delay Summary",
            chartTitle: "Frontline Ready Cars",
            paintColumnChart: false,
            paintChart: false,

            showSummaryDetailsDialog: false,
            summaryDetailTableData: [],
            summaryDetailTableTitle: "",
            summaryDetailsPaintTable: false,
            summaryMetricName: ""
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onUsedReconSummaryChartColumnClick = this.onUsedReconSummaryChartColumnClick.bind(this);
        this.onUsedReconSummaryChartCancelClick = this.onUsedReconSummaryChartCancelClick.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "Used Car Prep";

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/UsedReconditioningSummaryComponent/Summary", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            CommonService.clientAzureStorageLog("UsedReconditioningSummaryComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getFrontlineReadyCars() {
        let inputData = {
            storeNumber: this.state.selectedStore ? this.state.selectedStore.store_number : null,
            startDate: this.state.selectedMonth === "Last 30 Days" ? null : CommonService.formatServerDateTime(this.state.selectedMonth)
        };
        this.props.applicationInsights.trackTrace({ message: "/UsedReconditioningSummaryComponent/getFrontlineReadyCars", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/UsedReconditioning/GetFrontlineReadyCars", {
            method: "POST",
            headers: {
                'Content-Type': "application/json; charset=utf-8;"
            },
            body: JSON.stringify(inputData)
        })
            .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
            .then(parsedResponse => {
                if (parsedResponse) {
                    parsedResponse[0][1] = this.state.selectedStore.display_name;
                    parsedResponse[0][2] = "Thirty Day Avg Count";
                }

                this.setState({
                    chartTitle: this.state.selectedStore.display_name + " - Frontline Ready Cars for Last 30 days",
                    frontLineReadyData: parsedResponse,
                    paintChart: true
                }, () => {
                    this.setState({ paintChart: false });
                });
            })
            .catch(notOKResponse => {
                this.setState({
                    chartTitle: "",
                    frontLineReadyData: null,
                    paintChart: true
                }, () => {
                    this.setState({ paintChart: false });
                });
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedReconditioning/GetFrontlineReadyCars", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    onGetReportClick(selectedStore, selectedMonth) {
        try {
            let columnChartData = [
                [
                    { label: "Store", type: "string" },
                    { label: "Service", type: "number" },
                    { type: "string", role: "tooltip", p: { html: true } },
                    { label: "Detail", type: "number" },
                    { type: "string", role: "tooltip", p: { html: true } },
                    { label: "Service & Detail", type: "number" },
                    { type: "string", role: "tooltip", p: { html: true } },
                    { label: "Body", type: "number" },
                    { type: "string", role: "tooltip", p: { html: true } },
                    { label: "Recon", type: "number" },
                    { type: "string", role: "tooltip", p: { html: true } },
                    { label: "Photo", type: "number" },
                    { type: "string", role: "tooltip", p: { html: true } },
                    { label: "Total (Service to Photo - Right Axis)", type: "number" },
                    { type: "string", role: "tooltip", p: { html: true } }
                ]
            ];

            this.setState({ isLoadingData: true });
            let inputData = {
                storeNumber: selectedStore ? selectedStore.store_number : null,
                startDate: selectedMonth === "Last 30 Days" ? null : CommonService.formatServerDateTime(selectedMonth),
            };
            this.props.applicationInsights.trackTrace({ message: "/UsedReconditioningSummaryComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/UsedReconditioning/GetSummaryReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    parsedResponse.forEach((responseItem, index) => {
                        columnChartData.push(
                            [responseItem.store_code,
                            responseItem.service_out_age_avg === null ? 0 : responseItem.service_out_age_avg,
                            responseItem.service_out_tooltip = "<div style='padding: 5px; white-space: nowrap; font-family: Arial; font-size: 14px;'><b>" + responseItem.store_code
                            + "</b> <br />Service days: " + CommonService.formatNumber_1(responseItem.service_out_age_avg)
                            + "<br />Vehicle count: " + (responseItem.service_out_count === null ? 0 : responseItem.service_out_count) + "</div>",
                            responseItem.detail_out_age_avg === null ? 0 : responseItem.detail_out_age_avg,
                            responseItem.detail_out_tooltip = "<div style='padding: 5px; white-space: nowrap; font-family: Arial; font-size: 14px;'><b>" + responseItem.store_code
                            + "</b> <br />Detail days: " + CommonService.formatNumber_1(responseItem.detail_out_age_avg) +
                            "<br />Vehicle count: " + (responseItem.detail_out_count === null ? 0 : responseItem.detail_out_count) + "</div>",
                            responseItem.service_detail_out_age_avg === null ? 0 : responseItem.service_detail_out_age_avg,
                            responseItem.service_detail_out_tooltip = "<div style='padding: 5px; white-space: nowrap; font-family: Arial; font-size: 14px;'><b>" + responseItem.store_code
                            + "</b> <br />Service & Detail days: " + CommonService.formatNumber_1(responseItem.service_detail_out_age_avg)
                            + "<br />Vehicle count: " + (responseItem.service_detail_out_count === null ? 0 : responseItem.service_detail_out_count) + "</div>",
                            responseItem.body_out_age_avg === null ? 0 : responseItem.body_out_age_avg,
                            responseItem.body_out_tooltip = "<div style='padding: 5px; white-space: nowrap; font-family: Arial; font-size: 14px;'><b>" + responseItem.store_code
                            + "</b> <br />Body days: " + CommonService.formatNumber_1(responseItem.body_out_age_avg)
                            + "<br />Vehicle count: " + (responseItem.body_out_count === null ? 0 : responseItem.body_out_count) + "</div>",
                            responseItem.recon_out_age_avg === null ? 0 : responseItem.recon_out_age_avg,
                            responseItem.recon_out_tooltip = "<div style='padding: 5px; white-space: nowrap; font-family: Arial; font-size: 14px;'><b>" + responseItem.store_code
                            + "</b> <br />Recon days: " + CommonService.formatNumber_1(responseItem.recon_out_age_avg)
                            + "<br />Vehicle count: " + (responseItem.recon_out_count === null ? 0 : responseItem.recon_out_count) + "</div>",
                            responseItem.photo_out_age_avg === null ? 0 : responseItem.photo_out_age_avg,
                            responseItem.photo_out_tooltip = "<div style='padding: 5px; white-space: nowrap; font-family: Arial; font-size: 14px;'><b>" + responseItem.store_code
                            + "</b> <br />Photo days: " + CommonService.formatNumber_1(responseItem.photo_out_age_avg)
                            + "<br />Vehicle count: " + (responseItem.photo_out_count === null ? 0 : responseItem.photo_out_count) + "</div>",
                            responseItem.total_out_age_avg === null ? 0 : responseItem.total_out_age_avg,
                            responseItem.total_out_tooltip = "<div style='padding: 5px; white-space: nowrap; font-family: Arial; font-size: 14px;'><b>" + responseItem.store_code
                            + "</b> <br />Total (Service to Photo) days: " + CommonService.formatNumber_1(responseItem.total_out_age_avg)
                            + "<br />Vehicle count: " + (responseItem.total_out_count === null ? 0 : responseItem.total_out_count) + "</div>"
                            ])
                    });

                    this.setState({
                        selectedStore: selectedStore,
                        selectedMonth: selectedMonth,
                        columnChartTitle: selectedStore === null ? "All Stores" : selectedStore.display_name + " - Average delay for last 30 days",
                        columnChartData: columnChartData,
                        isLoadingData: false,
                        paintColumnChart: true
                    }, () => {
                        this.setState({ paintColumnChart: false });
                        this.getFrontlineReadyCars();
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        selectedStore: selectedStore,
                        columnChartTitle: "",
                        columnChartData: null,
                        isLoadingData: false,
                        paintColumnChart: true
                    }, () => {
                        this.setState({ paintColumnChart: true });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedReconditioning/GetSummaryReport", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onUsedReconSummaryChartColumnClick(event, rowIndex, columnIndex) {
        try {
            this.setState({ isLoadingData: true });
            let storeCode = this.state.columnChartData[rowIndex][0];
            let selectedStore = this.props.stores.filter(store => store.code === storeCode)[0];
            let summaryMetricName = columnIndex === 2 ? "Service"
                : columnIndex === 4 ? "Detail"
                    : columnIndex === 6 ? "Service & Detail"
                        : columnIndex === 8 ? "Body"
                            : columnIndex === 10 ? "Recon"
                                : columnIndex === 12 ? "Photo"
                                    : "Total";

            let inputData = {
                storeNumber: selectedStore ? selectedStore.store_number : null,
                startDate: this.state.selectedMonth === "Last 30 Days" ? null : CommonService.formatServerDateTime(this.state.selectedMonth),
                metricName: summaryMetricName
            };

            this.props.applicationInsights.trackTrace({ message: "/UsedReconditioningSummaryComponent/onUsedReconSummaryChartColumnClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/UsedReconditioning/GetSummaryDetailReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    switch (summaryMetricName) {
                        case "Service":
                            parsedResponse.forEach((responseItem, index) => {
                                responseItem.metric_data = responseItem.service;
                                responseItem.in_date_time = CommonService.formatDateTime_3(responseItem.in_date_time);
                                responseItem.out_date_time = CommonService.formatDateTime_3(responseItem.out_date_time);
                            });
                            break;
                        case "Detail":
                            parsedResponse.forEach((responseItem, index) => {
                                responseItem.metric_data = responseItem.detail;
                                responseItem.in_date_time = CommonService.formatDateTime_3(responseItem.in_date_time);
                                responseItem.out_date_time = CommonService.formatDateTime_3(responseItem.out_date_time);
                            });
                            break;
                        case "Service & Detail":
                            parsedResponse.forEach((responseItem, index) => {
                                responseItem.metric_data = responseItem.service_detail;;
                                responseItem.in_date_time = CommonService.formatDateTime_3(responseItem.in_date_time);
                                responseItem.out_date_time = CommonService.formatDateTime_3(responseItem.out_date_time);
                            });
                            break;
                        case "Body":
                            parsedResponse.forEach((responseItem, index) => {
                                responseItem.metric_data = responseItem.body;
                                responseItem.in_date_time = CommonService.formatDateTime_3(responseItem.in_date_time);
                                responseItem.out_date_time = CommonService.formatDateTime_3(responseItem.out_date_time);
                            });
                            break;
                        case "Recon":
                            parsedResponse.forEach((responseItem, index) => {
                                responseItem.metric_data = responseItem.recon;
                                responseItem.in_date_time = CommonService.formatDateTime_3(responseItem.in_date_time);
                                responseItem.out_date_time = CommonService.formatDateTime_3(responseItem.out_date_time);
                            });
                            break;
                        case "Photo":
                            parsedResponse.forEach((responseItem, index) => {
                                responseItem.metric_data = responseItem.photo;
                                responseItem.in_date_time = CommonService.formatDateTime_3(responseItem.in_date_time);
                                responseItem.out_date_time = CommonService.formatDateTime_3(responseItem.out_date_time);
                            });
                            break;
                        case "Total":
                            parsedResponse.forEach((responseItem, index) => {
                                responseItem.metric_data = responseItem.total;
                                responseItem.in_date_time = CommonService.formatDateTime_3(responseItem.in_date_time);
                                responseItem.out_date_time = CommonService.formatDateTime_3(responseItem.out_date_time);
                            });
                            break;
                        default:
                            break;
                    }
                    this.setState({
                        isLoadingData: false,
                        showSummaryDetailsDialog: true,
                        summaryDetailTableData: CommonService.addOrdinalColumn(parsedResponse),
                        summaryDetailTableTitle: "Used Recon Summary Detail for " + selectedStore.display_name + " " + summaryMetricName + " Out - "
                            + (this.state.selectedMonth === "Last 30 Days" ? this.state.selectedMonth : CommonService.formatMonthYear(new Date(this.state.selectedMonth))),
                        summaryMetricName: summaryMetricName,
                        summaryDetailsPaintTable: true
                    }, () => {
                        this.setState({ summaryDetailsPaintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        showSummaryDetailsDialog: false,
                        summaryDetailTableData: [],
                        summaryDetailTableTitle: "",
                        summaryDetailsPaintTable: true
                    }, () => {
                        this.setState({ summaryDetailsPaintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/UsedReconditioning/GetSummaryDetailReport", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onUsedReconSummaryChartCancelClick() {
        this.setState({ showSummaryDetailsDialog: false });
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <UsedReconditioningSummaryDetailsComponent
                applicationInsights={this.props.applicationInsights}
                showDialog={this.state.showSummaryDetailsDialog}
                tableData={this.state.summaryDetailTableData}
                tableTitle={this.state.summaryDetailTableTitle}
                paintTable={this.state.summaryDetailsPaintTable}
                summaryMetricName={this.state.summaryMetricName}
                onShowAlert={this.props.onShowAlert}
                onCancelClick={this.onUsedReconSummaryChartCancelClick}
            />
            <UsedReconditioningSummaryQueryComponent
                applicationInsights={this.props.applicationInsights}
                stores={this.props.stores} months={this.props.months} isLoadingData={this.state.isLoadingData}
                onGetReportClick={this.onGetReportClick}
            />
            <div className="dgdg-site-scrollable-content">
                <div className="card dgdg-card-gutter">
                    <UsedReconditioningChartComponent
                        applicationInsights={this.props.applicationInsights}
                        headerText={this.state.chartTitle}
                        chartData={this.state.frontLineReadyData}
                        paintChart={this.state.paintChart}
                    />
                </div>
                <div className="card">
                    <UsedReconditioningChartColumnComponent
                        applicationInsights={this.props.applicationInsights}
                        headerText={this.state.columnChartTitle}
                        chartData={this.state.columnChartData}
                        paintChart={this.state.paintColumnChart}
                        onUsedReconSummaryChartColumnClick={this.onUsedReconSummaryChartColumnClick}
                    />
                </div>
            </div>
        </div>;
    }
}
