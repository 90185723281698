import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

export class ServiceScheduleRODetailComponent extends React.Component {
    constructor(props) {
        super(props);

        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    render() {
        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.props.showDialog} title={this.props.tableTitle}
            cssName="dgdg-cit-detail-journal-entry-details-dialog"
            onCancelClick={this.props.onCancelClick}
        >
            {
                this.props.showSpinner
                    ? <DGDGSpinnerComponent showSpinner={this.props.showSpinner} cssName="dgdg-service-schedule-ro-detail-spinner" />
                    : <div className="dgdg-report-body">
                        <div className="dgdg-card-gutter">
                            <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.props.roDetailTitle} tableData={this.props.roTableData} paintTable={this.props.paintTable}
                                copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12], onCopyTableCallBack: this.props.onCopyTableCallBack }}
                            >
                                <DGDGTableRowComponent>
                                    <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} cssName="text-center" />
                                    <DGDGTableColumnComponent headerText="RO Number" dataColumn="ro_number" cssName="text-center" onCellClick={(!this.props.disableDrilldown && this.props.onRODetailCellClick)} />
                                    <DGDGTableColumnComponent headerText="Vehicle Id" dataColumn="vehicle_id" cssName="text-center" />
                                    <DGDGTableColumnComponent headerText="Open Date" dataColumn="open_date" dataType="date" cssName="text-center" />
                                    <DGDGTableColumnComponent headerText="Close Date" dataColumn="close_date" dataType="date" cssName="text-center" />
                                    <DGDGTableColumnComponent headerText="Service Advisor" dataColumn="display_name" cssName="text-nowrap" />
                                    <DGDGTableColumnComponent headerText="Customer Name" dataColumn="customer_display_name" cssName="text-center" />
                                    <DGDGTableColumnComponent headerText="Labor Sale" dataColumn="labor_sale" dataType="money_2" cssName="text-center" />
                                    <DGDGTableColumnComponent headerText="Labor Cost" dataColumn="labor_cost" dataType="money_2" cssName="text-center" />
                                    <DGDGTableColumnComponent headerText="Parts Sale" dataColumn="parts_sale" dataType="money_2" cssName="text-center" />
                                    <DGDGTableColumnComponent headerText="Parts Cost" dataColumn="parts_cost" dataType="money_2" cssName="text-center" />
                                    <DGDGTableColumnComponent headerText="Misc Sale" dataColumn="misc_sale" dataType="money_2" cssName="text-center" />
                                    <DGDGTableColumnComponent headerText="Misc Cost" dataColumn="misc_cost" dataType="money_2" cssName="text-center" />
                                    <DGDGTableColumnComponent headerText="Total Gross" dataColumn="total_sale" dataType="money_2" cssName="text-center" />
                                </DGDGTableRowComponent>
                            </DGDGTableV3Component>
                        </div>
                        <div className="dgdg-card-gutter">
                            <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.props.roLineTitle} tableData={this.props.roDetailTableData} paintTable={this.props.paintTable} showFooter
                                copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13], onCopyTableCallBack: this.props.onCopyTableCallBack }}
                            >
                                <DGDGTableRowComponent>
                                    <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} cssName="text-center" />
                                    <DGDGTableColumnComponent headerText="Line Code" dataColumn="line_code" sortColumn="line_code" cssName="text-center" />
                                    <DGDGTableColumnComponent headerText="Labor Type" dataColumn="labor_type" sortColumn="labor_type" footerText="Total" footerCssName="text-center" cssName="text-center" />
                                    <DGDGTableColumnComponent headerText="Op Code" dataColumn="op_code" sortColumn="op_code" cssName="text-center" />
                                    <DGDGTableColumnComponent headerText="Op Code Description" dataColumn="op_code_description" sortColumn="op_code_description" />
                                    <DGDGTableColumnComponent headerText="Technician" dataColumn="tech_number" sortColumn="tech_number" cssName="text-center" />
                                    <DGDGTableColumnComponent headerText="Sold Hours" dataColumn="sold_hours" sortColumn="sold_hours" dataType="number_1" footerCssName="text-center" footerFunction="sum" cssName="text-center" />
                                    <DGDGTableColumnComponent headerText="Labor Sale" dataColumn="labor_sale" sortColumn="labor_sale" dataType="money_2" footerCssName="text-center" footerFunction="sum" cssName="text-center" />
                                    <DGDGTableColumnComponent headerText="Labor Cost" dataColumn="labor_cost" sortColumn="labor_cost" dataType="money_2" footerCssName="text-center" footerFunction="sum" cssName="text-center" />
                                    <DGDGTableColumnComponent headerText="Parts Sale" dataColumn="parts_sale" sortColumn="parts_sale" dataType="money_2" footerCssName="text-center" footerFunction="sum" cssName="text-center" />
                                    <DGDGTableColumnComponent headerText="Parts Cost" dataColumn="parts_cost" sortColumn="parts_cost" dataType="money_2" footerCssName="text-center" footerFunction="sum" cssName="text-center" />
                                    <DGDGTableColumnComponent headerText="Misc Sale" dataColumn="misc_sale" sortColumn="misc_sale" dataType="money_2" footerCssName="text-center" footerFunction="sum" cssName="text-center" />
                                    <DGDGTableColumnComponent headerText="Misc Cost" dataColumn="misc_cost" sortColumn="misc_cost" dataType="money_2" footerCssName="text-center" footerFunction="sum" cssName="text-center" />
                                    <DGDGTableColumnComponent headerText="Total Gross" dataColumn="total_sale" sortColumn="total_sale" dataType="money_2" footerCssName="text-center" footerFunction="sum" cssName="text-center" />
                                </DGDGTableRowComponent>
                            </DGDGTableV3Component>
                        </div>
                        <div className="dgdg-card-gutter">
                            <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.props.roMLSTitle} tableData={this.props.roMLSTableData} paintTable={this.props.paintTable}
                                copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7], onCopyTableCallBack: this.props.onCopyTableCallBack }}
                            >
                                <DGDGTableRowComponent>
                                    <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} cssName="text-center" />
                                    <DGDGTableColumnComponent headerText="Line Code" dataColumn="line_code" sortColumn="line_code" cssName="text-center" />
                                    <DGDGTableColumnComponent headerText="Labor Type" dataColumn="labor_type" sortColumn="labor_type" cssName="text-center" />
                                    <DGDGTableColumnComponent headerText="MLS Type" dataColumn="mls_type" sortColumn="mls_type" cssName="text-center" />
                                    <DGDGTableColumnComponent headerText="OP Code" dataColumn="op_code" sortColumn="op_code" cssName="text-center" />
                                    <DGDGTableColumnComponent headerText="OP Code Description" dataColumn="op_code_desc" sortColumn="op_code_desc" cssName="text-center" />
                                    <DGDGTableColumnComponent headerText="Misc Sale" dataColumn="misc_sale" sortColumn="misc_sale" dataType="money_2" cssName="text-center" />
                                    <DGDGTableColumnComponent headerText="Misc Cost" dataColumn="misc_cost" sortColumn="misc_cost" dataType="money_2" cssName="text-center" />
                                </DGDGTableRowComponent>
                            </DGDGTableV3Component>
                        </div>
                    </div>
            }
        </DGDGModalDialogComponent>;
    }
}
